<template>
  <div class="text-template-list">
    <v-container
      fluid
      grid-list-xl
    >
      <v-layout
        row
        wrap
      >
        <v-flex lg12>
          <Toolbar
            :handle-add="addHandler"
            :enable-refresh="true"
            :handle-refresh="refreshData"
            :title="$t('Addons')"
          />
          <DataFilter
            :handle-filter="onSendFilter"
            :handle-reset="resetFilter"
          >
            <AddonFilterForm
              :values="filters"
              ref="filterForm"
              slot="filter"
            />
          </DataFilter>
          <v-data-table
            :headers="headers"
            :items="items"
            :items-per-page.sync="options.itemsPerPage"
            :loading="isLoading"
            :loading-text="$t('Loading...')"
            :options.sync="options"
            :server-items-length="totalItems"
            @update:options="onUpdateOptions"
            class="elevation-1"
            item-key="@id"
            v-model="selected"
          >
            <template
              slot="item.enabled"
              slot-scope="{ item }"
            >
              <v-icon v-if="item.enabled">mdi-check-circle</v-icon>
              <v-icon v-else>mdi-close-circle</v-icon>
            </template>
            <ActionCell
              :handle-delete="() => deleteHandler(props.item)"
              :handle-edit="() => editHandler(props.item)"
              slot="item.action"
              slot-scope="props"
            ></ActionCell>
          </v-data-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import { mapFields } from "vuex-map-fields";
  import ListMixin from "../../mixins/ListMixin";
  import ActionCell from "../../components/ActionCell";
  import Toolbar from "../../components/Toolbar";
  import DataFilter from "../../components/DataFilter";
  import AddonFilterForm from "../../components/addon/Filter";

  export default {
    name: "AddonList",
    servicePrefix: "Addon",
    mixins: [ListMixin],
    components: {
      Toolbar,
      ActionCell,
      DataFilter,
      AddonFilterForm,
    },
    data() {
      return {
        headers: [
          { text: this.$i18n.t("title"), value: "title" },
          { text: this.$i18n.t("price"), value: "price" },
          { text: this.$i18n.t("sortOrder"), value: "sortOrder" },
          {
            text: this.$i18n.t("Actions"),
            value: "action",
            sortable: false,
            align: "center",
          },
        ],
        selected: [],
        filters: {
          __Fexists: { parent: false }
        },
        showAddonDialog: false,
        addonItem: {},
        showAddonDialogForm: false,
        addonFormItem: {},
        addonDialogTitle: this.$t("AddAddon"),
      };
    },
    computed: {
      ...mapGetters("addon", {
        items: "list",
      }),
      ...mapFields("addon", {
        deletedItem: "deleted",
        error: "error",
        isLoading: "isLoading",
        resetList: "resetList",
        totalItems: "totalItems",
        view: "view",
      }),
    },
    methods: {
      ...mapActions("addon", {
        getPage: "fetchAll",
        deleteItem: "del",
        clearAddonData: 'resetAllData',
      }),
      resetFilter() {
        this.filters = {
          profile: {},
        };
      },
      addonDialogControl(close = false) {
        this.showAddonDialogForm = !close;
      },
      handleCreated(item) {
        console.log(item);
      },
      handleUpdated(item) {
        console.log(item);
      },
      createOrEditAddonHandler(item) {
        this.addonFormItem = item ?? {};
        if (item) {
          this.addonDialogTitle = this.$t("EditAddon");
        } else {
          this.addonDialogTitle = this.$t("AddAddon");
        }
        this.addonDialogControl();
      },
      refreshData() {
        this.clearAddonData();
        this.onSendFilter();
      }
    },
  };
</script>
