<template>
    <v-container fluid>
      <v-row>
        <v-col cols="12" sm="4">
          <v-text-field :label="$t('title')" type="text" v-model="item.title" />
        </v-col>
      </v-row>
    </v-container>
  </template>
  
  <script>
  export default {
    name: "AddonFilter",
    props: {
      values: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {};
    },
    mounted() {},
  
    computed: {
      // eslint-disable-next-line
      item() {
        return this.initialValues || this.values;
      },
    },
    methods: {},
  };
  </script>
  